export const Constants = {
    //bagged at 200kcal a serving
    tailwind: {
        serving: '25ml',
        qty: '1, 2, 3, 4, 5',
        calories: '50',
        potassium: '45',
        sodium: '155',
        magnesium: '4',
        calcium: '13',
        protein: '0',
    },
    tailwindserv: [
        '0',
        '25',
        '50',
        '75',
        '100',
        '125',
        '150',
        '175',
        '200',
    ],
    liquids: [
        0,
        1,
        2,
        3,
        4,
        5,
        6,
        7,
        8,
        9,
    ],
    times: [
        '07:00-08:00',
        '08:01-09:00',
        '09:01-10:00',
        '10:01-11:00',
        '11:01-12:00',
        '12:01-13:00',
        '13:01-14:00',
        '14:01-15:00',
        '15:01-16:00',
        '16:01-17:00',
        '17:01-18:00',
        '18:01-19:00',
        '19:01-20:00',
        '20:01-21:00',
        '21:01-22:00',
    ],
    h20: [
        '0',
        '25',
        '50',
        '75',
        '100',
        '125',
        '150',
        '175',
        '200',
    ],
    morningHrs: [
        '07:00-08:00',
        '08:01-09:00',
        '09:01-10:00',
        '10:01-11:00',
        '11:01-12:00',
    ],
    afternoonHrs: [
        '12:01-13:00',
        '13:01-14:00',
        '14:01-15:00',
        '15:01-16:00',
        '16:01-17:00',
    ],
    eveningHrs: [
        '17:01-18:00',
        '18:01-19:00',
        '19:01-20:00',
        '20:01-21:00',
        '21:01-22:00',
    ],
};